export const FirebaseConfig = {
	"projectId": "cruiser-4470c",
	"appId": "1:250304072479:web:c0c44db46b54bde8bcbe2a",
	"databaseURL": "https://cruiser-4470c-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "cruiser-4470c.appspot.com",
	"locationId": "europe-west6",
	"apiKey": "AIzaSyBA7H0VaRr2Tu6F0ds75N7SjelAgVY8qZA",
	"authDomain": "cruiser-4470c.firebaseapp.com",
	"messagingSenderId": "250304072479",
	"measurementId": "G-H56N6EBQKL"
};